import Vue from 'vue'
import App from './App.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU'
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import routes from "@/router";
import axios from "axios";

const router = new VueRouter({
  mode: 'history',
  routes
});

axios.defaults.baseURL = 'https://dozation-pult.stage.millennium-falcon.team';

Vue.use(VueRouter)


Vue.use(ElementUI, {locale});


Vue.component('v-select', vSelect)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
